import(/* webpackMode: "eager", webpackExports: ["Carousel"] */ "/app/node_modules/.pnpm/@mantine+carousel@7.13.4_@mantine+core@7.13.4_@mantine+hooks@7.13.4_embla-carousel-react@8.3._oktvy3yj5mc6t5vtazennlrmva/node_modules/@mantine/carousel/esm/Carousel.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["CarouselSlide"] */ "/app/node_modules/.pnpm/@mantine+carousel@7.13.4_@mantine+core@7.13.4_@mantine+hooks@7.13.4_embla-carousel-react@8.3._oktvy3yj5mc6t5vtazennlrmva/node_modules/@mantine/carousel/esm/CarouselSlide/CarouselSlide.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useAnimationOffsetEffect"] */ "/app/node_modules/.pnpm/@mantine+carousel@7.13.4_@mantine+core@7.13.4_@mantine+hooks@7.13.4_embla-carousel-react@8.3._oktvy3yj5mc6t5vtazennlrmva/node_modules/@mantine/carousel/esm/use-animation-offset-effect.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["AspectRatio"] */ "/app/node_modules/.pnpm/@mantine+core@7.13.4_@mantine+hooks@7.13.4_react-dom@19.0.0-rc-45804af1-20241021_react@19.0.0_vfjhlx5twugqpqb6iwzgankd54/node_modules/@mantine/core/esm/components/AspectRatio/AspectRatio.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Card"] */ "/app/node_modules/.pnpm/@mantine+core@7.13.4_@mantine+hooks@7.13.4_react-dom@19.0.0-rc-45804af1-20241021_react@19.0.0_vfjhlx5twugqpqb6iwzgankd54/node_modules/@mantine/core/esm/components/Card/Card.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SimpleGrid"] */ "/app/node_modules/.pnpm/@mantine+core@7.13.4_@mantine+hooks@7.13.4_react-dom@19.0.0-rc-45804af1-20241021_react@19.0.0_vfjhlx5twugqpqb6iwzgankd54/node_modules/@mantine/core/esm/components/SimpleGrid/SimpleGrid.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Stack"] */ "/app/node_modules/.pnpm/@mantine+core@7.13.4_@mantine+hooks@7.13.4_react-dom@19.0.0-rc-45804af1-20241021_react@19.0.0_vfjhlx5twugqpqb6iwzgankd54/node_modules/@mantine/core/esm/components/Stack/Stack.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mantine+core@7.13.4_@mantine+hooks@7.13.4_react-dom@19.0.0-rc-45804af1-20241021_react@19.0.0_vfjhlx5twugqpqb6iwzgankd54/node_modules/@mantine/core/esm/core/Box/Box.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["LexicalEditorTheme"] */ "/app/node_modules/.pnpm/@payloadcms+richtext-lexical@3.0.0-beta.123_@faceless-ui+modal@3.0.0-beta.2_@faceless-ui+scro_ktpfdcq7qan5nzw7q3arbqglxi/node_modules/@payloadcms/richtext-lexical/dist/lexical/theme/EditorTheme.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.1_react-dom@19.0.0-rc-45804af1-20241021_react@19.0.0-rc-45804af1-20241021/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["Container"] */ "/app/src/modules/common/components/Container/Container.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/modules/layout/templates/page/page-template.module.css");
;
import(/* webpackMode: "eager" */ "/app/src/modules/common/components/CoverImage/CoverImage.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["VideoCard"] */ "/app/src/modules/video/components/v2/VideoCard/VideoCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VideoFilters"] */ "/app/src/modules/video/components/v2/VideoFilters/VideoFilters.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VideoPreview"] */ "/app/src/modules/video/components/v2/VideoPreview/VideoPreview.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/modules/video/templates/AllVideosTemplate.module.css");
;
import(/* webpackMode: "eager" */ "/app/src/modules/video/components/VideoCarouselPlaceholder.module.css");
