'use client'
import { Video } from '@/payload-types'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import React from 'react'
import classes from './VideoPreview.module.css'
import {
  Anchor,
  Box,
  Center,
  Flex,
  Group,
  Modal,
  ScrollAreaAutosize,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import { Container } from '@/modules/common/components/Container'
import { WatchButton } from '@/modules/watch/components/WatchButton'
import { GiftButton } from '@/modules/watch/components/GiftButton'
import { VideoInfo } from '../VideoInfo'
import { ButtonLink } from '@/modules/common/components/ButtonLink'

export const VideoPreview = ({ video }: { video: Video }) => {
  const [opened, { toggle, close }] = useDisclosure(false)
  const isDesktop = useMediaQuery('(min-width: 36em)')

  return (
    <Box className={classes.content}>
      {video ? (
        <Container>
          <Stack className={classes.contentWrapper}>
            <Title className={classes.title}>{video?.title}</Title>
            <Text lineClamp={3}>{video?.plot}</Text>
            <Anchor
              component="button"
              fw={700}
              mr="auto"
              onClick={toggle}
              className={'mantine-active'}
            >
              Подробнее
            </Anchor>
            <Flex gap="sm" direction={{ base: 'column', xs: 'row' }}>
              <WatchButton video={video} />
              <GiftButton video={video} />
            </Flex>
          </Stack>
          <Modal
            opened={opened}
            onClose={close}
            title={
              <Text fw={700} c="white" size="lg">
                {video?.title}
              </Text>
            }
            yOffset={`var(--header-height)`}
            padding="xl"
            size="lg"
            radius="md"
            zIndex={300}
            scrollAreaComponent={ScrollAreaAutosize}
            fullScreen={!isDesktop}
          >
            <VideoInfo video={video} />
            <Flex justify="space-between" direction={{ base: 'column', xs: 'row' }} gap="xs">
              <Group gap="xs">
                <WatchButton video={video} flex={1} size="sm" />
                <GiftButton video={video} flex={1} size="sm" />
              </Group>
              <ButtonLink
                variant="default"
                href={`/theatre/${typeof video.theatre === 'object' ? video?.theatre?.slug : '/'}`}
              >
                Все события места
              </ButtonLink>
            </Flex>
          </Modal>
        </Container>
      ) : (
        <Container>
          <Center>
            <Title ff="text" fw={500} fz="h2">
              Спектаклей не найдено
            </Title>
          </Center>
        </Container>
      )}
    </Box>
  )
}
